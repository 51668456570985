export default function calculateDistance(
    lat1: number,
    lon1: number,
    lat2: number | undefined,
    lon2: number | undefined,
) {
    if (lat2 !== undefined && lon2 !== undefined) {
        const R = 6371; // km (change this constant to get miles)
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
            + Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180))
            * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        if (d > 1) return Math.round(d);
        return Number.parseFloat(d.toFixed(2));
    }
    return 1000;
}
